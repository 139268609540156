import React, { useState } from 'react';
import '../styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import divider from '../images/divider-colored.svg';

const TechStackComponent = ({ title, icons }) => {
  return (
    <>
      <div className='tech-stack-component pt-3 pb-0 text-center'>
        <div className='container'>
          <div className='tech-stack-subtitle p-1'>
            <div className='tech-stack-divider'>
              <img src={divider} alt='divider' />
            </div>
            <p className='SectionHeading tech-stack-heading'>{title}</p>
          </div>
          <div className='tech-stack-icons row wow zoomIn'>
            {Object.keys(icons).map(i => {
              return (
                <div className='col-3 col-sm-2 col-md-2 col-lg-1 tech-stack-icon-div'>
                  <div className='icon-card'>
                    <img
                      className='technology-item'
                      src={icons[i].file.url}
                      alt='icon'
                    />
                  </div>
                  <p
                    className='p-3 primary-text-color font-weight-620'
                    style={{ padding: '0px', margin: '0px' }}
                  >
                    {icons[i].title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TechStackComponent;
