import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import TechStackComponent from '../components/TechStackComponent';

const TechStack = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulTechStack(sort: { fields: [priority], order: ASC }) {
          nodes {
            priority
            title
            icons {
              file {
                url
              }
              title
            }
          }
        }
      }
    `
  );

  return (
    <>
      <Layout isWithoutHero={true}>
        <div style={{ marginTop: '100px', marginBottom: '50px' }}>
          <div className='handbook-heading' style={{ marginTop: '140px' }}>
            Tech Stack
          </div>
          {Object.keys(data.allContentfulTechStack.nodes).map(i => {
            return (
              <TechStackComponent
                title={data.allContentfulTechStack.nodes[i].title}
                icons={data.allContentfulTechStack.nodes[i].icons}
              />
            );
          })}
        </div>
      </Layout>
    </>
  );
};

export default TechStack;
